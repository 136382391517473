import Animatinos from "./components/Animations";
import CountDown from "./components/CountDown";
import Lightbox from "./components/Lightbox";
import Program from "./components/program";

document.addEventListener("DOMContentLoaded", () => {
    const time = new Date("2024-11-29T18:00:00").getTime();
    // const time = new Date("2024-10-17T15:00:00").getTime();

    // Check if countdown element exists on the page and initialize the countdown
    document.querySelector(".countdown") ? CountDown({ time }) : null;
    document.querySelector(".lightbox") ? Lightbox() : null;
    document.querySelector(".event-container") ? Program() : null;
    // Animatinos();
});
